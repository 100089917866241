<template>
  <div>
    <!-- 新建弹窗 -->
    <el-dialog :title="dialog.add.title" :visible.sync="dialog.add.show" v-model="dialog.add.show"
               :close-on-click-modal="false" width='65%'>
      <div v-if="step === 1">
        <el-form :model="addForm" :rules="addRules" ref="addFormRef" label-width="160px"
                 style="min-width='800px'">
          <el-form-item label="新增条:" prop="bn">
            <el-input style="width:200px" v-model="addForm.bn" clearable></el-input>
          </el-form-item>
          <el-form-item label="所属规范:" prop="standardId">
            <div style="border: 1px solid lightgray; padding: 0px 5px;border-radius: 5px;width: 78%">
              {{ needData.name }}
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div v-else-if="step === 2">
        <el-form v-if="dialog.add.editing" :model="addForm" :rules="addRules" ref="addFormRef" label-width="160px"
                 style='min-width="800px"'>
          <el-form-item label="条文编号:" prop="bn">
            <el-input style="width:200px" v-model="addForm.bn" clearable></el-input>
          </el-form-item>
          <el-form-item label="所属规范:" prop="standardId">
            <el-select v-model="addForm.standardId" clearable style="width: 100%;">
              <el-option v-for="item in standardOptions" :key="item.id" :value="item.id" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-form label-width="160px" style='min-width="800px"'>
          <el-form-item label="" prop="">
            <el-tree style="margin-bottom:50px" :data="standardTreedata" node-key="id" default-expand-all
                     :expand-on-click-node="false">
                            <span @click="checkNode(node, data)" class="custom-tree-node" slot-scope="{ node, data }">
                                <span>{{ node.data.bn }}</span>
                              <!-- <span v-if="node.level === 1">{{ node.data.bn }}条</span>
                              <span v-else-if="node.level === 2">第{{ node.data.bn }}款</span>
                              <span v-else-if="node.level === 3">第{{ node.data.bn }}项</span>
                              <span v-else-if="node.level === 4">第{{ node.data.bn }}目</span> -->
                                <span>
                                    <el-button
                                        type="text"
                                        :disabled="node.level === 4"
                                        size="mini"
                                        @click.stop="() => addChild(node, data)"
                                    >
                                        新增子节点
                                    </el-button>
                                    <el-button
                                        type="text"
                                        :disabled="node.level === 1"
                                        size="mini"
                                        @click.stop="() => delItem(node, data)"
                                    >
                                        删除节点
                                    </el-button>
                                </span>
                            </span>
            </el-tree>
          </el-form-item>
        </el-form>
        <el-form v-if="!checkedTop" :model="form" ref="formRef" label-width="160px" style='min-width="800px"'>
          <el-form-item label="编号:" prop="bn">
            <el-input style="width:200px" v-model="form.bn"></el-input>
          </el-form-item>
          <el-form-item label="条文内容:" prop="content">
            <el-input type="textarea" :rows="4" v-model="form.content"></el-input>
          </el-form-item>
          <el-form-item label="条文类型:" prop="typeId">
            <el-select v-model="form.typeId">
              <el-option v-for="item in typeOptions" :key="item.id" :value="item.id"
                         :label="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button @click="saveRuleSubmit" type="primary">保存子节点</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button v-if="step === 1" type="primary" @click="nextStep"
                   :loading="dialog.add.loading">下一步
        </el-button>
        <el-button v-else-if="step === 2 && dialog.add.editing" type="primary" @click="nextStep"
                   :loading="dialog.add.loading">确认
        </el-button>
        <el-button @click.native="addDialogClose">关闭</el-button>
      </div>
    </el-dialog>
    <!-- 条纹规则弹窗 -->
    <el-dialog :title="dialog.rule.title" :visible.sync="dialog.rule.show" v-model="dialog.rule.show"
               :close-on-click-modal="false" width='50%'>
      <el-form :model="form" :rules="rules" ref="formRef" label-width="160px" style='"min-width"="800px"'>
        <el-form-item label="编号:" prop="bn">
          <el-input v-model="form.bn"></el-input>
        </el-form-item>
        <el-form-item label="条文内容:" prop="content">
          <el-input type="textarea" v-model="form.content"></el-input>
        </el-form-item>
        <el-form-item label="条文类型:" prop="typeId">
          <el-select v-model="form.typeId">
            <el-option v-for="item in typeOptions" :key="item.id" :value="item.id"
                       :label="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="ruleDialogClose">取消</el-button>
        <el-button type="primary" @click="addRuleSubmit" :loading="dialog.rule.loading">确认</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import {
  addDrStandardItem, addDrStandardItemRule, addQuestion,
  deleteDrStandardItem, deleteDrStandardItemNotes,
  getAllDrStandardList, getAllStandardItemNotesByItemRootId, getAuditContentAllList,
  getByNamespace,
  getDrStandardItemById, getDrStandardItemList, getDrStandardItemNotesList, saveDrStandardItemNotes,
  updateDrStandardItem, updateQuestion, uploadImagesUrl
} from '@/api/api'
import util from '../../util/date'

export default {
  name: 'AddTiaoWen',
  created () {
    this.init()
  },
  watch: {
    dialogVisible (newValue, oldValue) {
      this.dialogVisibleData = newValue;
      // 当 dialogVisible 值发生变化时执行的操作
      if (newValue===true) {
        // 对话框显示时的操作
        this.handleAdd()
        console.log(this.dialogVisible,'dialog')
      } else {
        // 对话框隐藏时的操作
      }
    }
  },
  props:{
    dialogTitle: "",
    dialogVisible:false,
    needData: { },
  },
  data(){
    var ruleBnValidator = function (rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入编号'))
      } else {
        let reg = new RegExp(/^[\d\.\-\(\),、（），aAbBcCdDeEfF]*$/)
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('编号只能输入数字和.-(),、（），等字符'))
        }
      }
    }
    return {
      utils: '',
      buttonList: [],
      unNeedData: '',
      dialogVisibleData : this.dialogVisible,
      filters: {
        keyword: '',
        standardId: null
      },

      filteredStandardOptions: [],// 过滤后的规范选项数据
      standardTreedata: [],
      step: 1,
      tableData: [],
      listLoading: false,
      //关于分页的obj
      pages: {
        pageSize: 20,//默认每页条数
        pageArr: [10, 20, 30, 40],//默认每页条数选择
        pageIndex: 1, //默认进入页
        dataCount: 20, //默认总页数
      },
      // 弹窗相关变量
      dialog: {
        add: {
          show: false,
          title: '',
          editing: false,
          loading: false,
        },
        rule: {
          show: false,
          title: '',
        },
        note: {
          show: false,
          title: ''
        },
        addNote: {
          show: false,
          showLoading: false,
          isEdit: false
        },
        review: {
          show: false
        },
        reviewPic: {
          show: false,
          dialogImageUrl: ''
        }
      },
      saveShowLoading: false,
      checkedTop: true,
      // 新增条文form  新增条文规则form
      addForm: {
        id: undefined,
        bn: '',
        standardId: undefined,
      },
      form: {
        id: undefined,
        bn: '',
        content: '',
        parentId: undefined,
        typeId: undefined,
      },
      noteForm: {
        bn: '',
        title: '',
        picList: '',
        contentList: [{ bn: '', content: '', typeId: undefined }]
      },
      addRules: {
        bn: [{
          required: true,
          message: '编号只能输入数字和.-(),、（），等字符',
          trigger: 'blur',
          validator: ruleBnValidator
        }],
        standardId: [{ required: true, message: '请选择所属规范', trigger: 'change' }],
      },
      rules: {
        bn: [{
          required: true,
          message: '编号只能输入数字和.-(),、（），等字符',
          trigger: 'blur',
          validator: ruleBnValidator
        }],
        content: [{ required: true, message: '请输入条文内容', trigger: 'blur' }],
        typeId: [{ required: true, message: '请选择类型', trigger: 'change' }],
      },
      noteRules: {
        bn: [{
          required: true,
          message: '编号只能输入数字和.-(),、（），等字符',
          trigger: 'blur',
          validator: ruleBnValidator
        }],
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        // picList: [{ required: true, message: '请上传图片', trigger: 'change' }],
      },
      // 当前条文id
      standardItemId: undefined,
      // options
      standardOptions: [], //所属规范
      typeOptions: [], //条文类型
      typeOptionsMore: [], //条文类型
      // 注
      unNeed: {
        pic: false,
        content: false
      },
      notesList: [],
      allNotesList: [],
      actions: '',
      fileList: [],
      imageUrl: ''
    }
  },
  methods: {
    toggleDialog () {
      this.$emit('update:dialogVisible', this.dialogVisibleData); // 触发自定义事件
    },
    callFunction (item) {
      if (item.func == 'getDataList') {
        this.pages.pageIndex = 1
      }
      this[item.func].apply(this, item)
    },
    // 初始化获取option
    init () {
      this.actions = uploadImagesUrl
      this.getDataList()
      getByNamespace({ namespace: 'BizService.DrAuditRep.QuestionPropertie' }).then(res => {
        if (res.data.success) {
          this.propertyOptions = res.data.response
        }
      })
      getByNamespace({ namespace: 'BizService.DrAuditRep.QuestionType' }).then(res => {
        if (res.data.success) {
          this.typeOptionsMore = res.data.response
        }
      })

      // 获取类型
      getByNamespace({ namespace: 'BizService.DrAuditRep.StandardItemType' }).then(res => {
        if (res.data.success) {
          this.typeOptions = res.data.response
        }
      })
      // 获取所有规范
      getAllDrStandardList().then(res => {
        if (res.data.success) {
          let options = []
          res.data.response && res.data.response.forEach(item => {
            let obj = {
              id: item.id,
              name: `${item.name} ${item.bn}(${item.version})`
            }
            options.push(obj)
          })
          this.standardOptions = options
          this.filteredStandardOptions = this.standardOptions
        }
      })
    },
    // 列表查询接口
    getDataList () {
      let params = {
        keyword: this.filters.keyword,
        pageIndex: this.pages.pageIndex,
        pageSize: this.pages.pageSize,
        standardId: this.filters.standardId
      }
      this.listLoading = true
      getDrStandardItemList(params).then(res => {
        if (res.data.success) {
          let list = []
          this.getListArray = res.data.response.data
          res.data.response.data && res.data.response.data.forEach(item => {
            let content = JSON.parse(item.content)
            let obj = {
              ...item,
              content: content
            }
            list.push(obj)
          })
          this.tableData = list
          // console.log(this.tableData);
          this.pages.dataCount = res.data.response.dataCount
          this.listLoading = false
        }
      })
    },
    // 新建条文
    handleAdd () {
      this.step = 1
      this.dialog.add.show = true
      this.dialog.add.loading = false
      this.dialog.add.title = '新增条文编号'
      this.addForm = {
        id: undefined,
        bn: '',
        standardId: undefined,
      }
      this.form = {
        id: undefined,
        bn: '',
        content: '',
        parentId: undefined,
        typeId: undefined,
      }
    },
    // 获取条文树形结构
    async getTree () {
      await this.getAllNotes()
      let res = await getDrStandardItemById({ id: this.standardItemId })
      if (res.data.success) {
        let tree = JSON.parse(res.data.response.content)
        if (tree && tree.length > 0) {
          var newTree = tree[0].children
          if (newTree && newTree.length > 0) {
            var sortTree = newTree[0].children
            sortTree.sort(function (a, b) {
              var key = 'bn'
              return parseInt(a[key]) - parseInt(b[key])
            })
          }
        }
        this.standardTreedata = tree
        await this.treeDataAddNote(this.standardTreedata)
      }
      // console.log(this.standardTreedata);
    },
    // 获取所有注
    async getAllNotes () {
      let notesRes = await getAllStandardItemNotesByItemRootId({ itemRootId: this.standardItemId })
      if (notesRes.data.success) {
        let list = []
        notesRes.data.response && notesRes.data.response.forEach(item => {
          let pic = []
          if (item.picList !== '') {
            pic = item.picList.split(',')
          }
          let obj = {
            bn: item.bn,
            title: item.title,
            id: item.id,
            drStandardItemId: item.drStandardItemId,
            picList: pic
          }
          let str = item.content
          if (str.indexOf('[') === -1) {
            // 不为json字符串
            obj.content = [str]
          } else {
            obj.content = JSON.parse(str)
          }
          list.push(obj)
        })
        this.allNotesList = list
      }
    },
    // 递归给树的每个子节点加上注
    treeDataAddNote (list) {
      list.forEach((i, index) => {
        if (i.children) {
          let data = this.allNotesList.filter(item => {
            return item.drStandardItemId === i.id
          })
          list[index].noteList = data
          this.treeDataAddNote(i.children)
        } else {
          let data = this.allNotesList.filter(item => {
            return item.drStandardItemId === i.id
          })
          list[index].noteList = data
        }
      })
    },
    // 预览条文内容
    async onReview (id) {
      this.standardItemId = id
      await this.getTree()
      this.dialog.review.show = true
    },
    // 查看条文规则
    nextStep () {
      this.addForm.standardId = this.needData.id
      this.$refs.addFormRef.validate((valid) => {
        if (valid) {
          if (this.addForm.id === undefined) {
            addDrStandardItem(this.addForm).then(res => {
              if (res.data.success) {
                this.standardTreedata = []
                this.step += 1
                this.dialog.add.title = '新增条文内容'
                this.standardItemId = res.data.message
                this.getTree()
                this.getDataList()
              } else {
                this.$message({
                  message: res.data.message,
                  type: 'error'
                })
              }
            })
          } else {
            // 编辑条文
            let params = {
              content: '',
              ...this.addForm
            }
            updateDrStandardItem(params).then(res => {
              if (res.data.success) {
                this.$message({
                  message: res.data.message,
                  type: 'success'
                })
                this.addDialogClose()
                this.getDataList()
              } else {
                this.$message({
                  message: res.data.message,
                  type: 'error'
                })
              }
            })
          }

        }
      })
    },
    // 点击子节点
    checkNode (node, data) {
      if (node.level !== 1) {
        this.checkedTop = false
        getDrStandardItemById({ id: data.id }).then(res => {
          if (res.data.success) {
            this.form = {
              id: data.id,
              bn: res.data.response.bn,
              content: res.data.response.content,
              parentId: res.data.response.parentId,
              typeId: res.data.response.typeId,
              standardId: res.data.response.standardId
            }
          }
        })
      } else {
        // 条文级没有内容
        this.checkedTop = true
        this.form = {
          id: data.id,
          bn: '',
          content: '',
          parentId: 0,
          typeId: undefined,
          standardId: undefined
        }
      }
    },
    // 新增子节点
    addChild (node, data) {
      this.dialog.rule = {
        show: true,
        title: '新增子节点'
      }
      this.form = {
        parentId: data.id,
        id: undefined,
        bn: '',
        content: '',
        typeId: undefined,
      }
    },
    //新增子节点 提交
    addRuleSubmit () {
      let params = {
        bn: this.form.bn,
        content: this.form.content,
        parentId: this.form.parentId,
        typeId: this.form.typeId,
      }
      addDrStandardItemRule(params).then(res => {
        if (res.data.success) {
          this.getTree()
          this.ruleDialogClose()
        } else {
          this.$message({
            message: res.data.message,
            type: 'error'
          })
        }
      })
    },
    // 保存子节点
    saveRuleSubmit () {
      updateDrStandardItem(this.form).then(res => {
        if (res.data.success) {
          this.$message({
            message: res.data.message,
            type: 'success'
          })
          this.getTree()
        } else {
          this.$message({
            message: res.data.message,
            type: 'error'
          })
        }
      })
    },
    // 新增注的内容框
    addContentInput () {
      this.noteForm.contentList.push({ bn: '', content: '', typeId: undefined })
    },
    // 删除最后一个内容框
    delContentInput () {
      this.noteForm.contentList.pop()
    },
    // 输入时判断content中bn是否有重复
    changeContent (e, index) {
      this.noteForm.contentList.forEach((item, i) => {
        if (item.bn === e && i !== index) {
          this.$message({
            message: '编号不能重复',
            type: 'error'
          })
        }
      })
    },
    // 保存注
    saveNote () {
      this.$refs.noteRef.validate((valid) => {
        if (valid) {
          let params = {
            bn: this.noteForm.bn,
            title: this.noteForm.title,
            drStandardItemId: this.standardItemId
          }
          // 内容数组转json
          if (this.noteForm.contentList.length > 0) {
            if (this.noteForm.contentList[0].bn !== '') {
              this.noteForm.contentList.forEach(item => {
                item.id = item.bn
              })
              params.content = JSON.stringify(this.noteForm.contentList)
            } else {
              params.content = ''
            }
          } else {
            params.content = ''
          }

          // 图片数组转json
          // gws
          if (this.fileList.length > 0) {
            let url = ''
            this.fileList.forEach(file => {
              url = url + file.response.response.weburl + ','
            })
            params.picList = url.substring(0, url.length - 1)
          }
          // 不需要图片
          if (this.unNeed.pic) {
            params.bn = ''
            params.title = ''
            params.picList = ''
          }
          // 不需要注
          if (this.unNeed.content) {
            params.content = ''
          }

          // 判断图注编号是否重复 不需要判断 0206 林
          // if (params.bn !== '') {
          //     this.notesList.forEach(item => {
          //         if (item.bn === params.bn) {
          //             this.$message({
          //                 type: 'error',
          //                 message: '编号不能重复'
          //             })
          //             return
          //         }
          //     })
          // }

          // 判断contentList是否重复

          if (this.noteForm.contentList.length > 0 && this.noteForm.contentList[0].bn !== '') {
            let arr = this.noteForm.contentList.map(value => value.bn)
            let setArr = new Set(arr)
            // 因为Set会自动去重 所以和原数组长度不同
            if (setArr.size !== arr.length) {
              this.$message.error('内容编号不能重复！')
              return
            }
          }

          if (this.noteForm.id) {
            params.id = this.noteForm.id
          }
          this.saveShowLoading = true
          saveDrStandardItemNotes(params).then(res => {
            if (res.data.success) {
              this.getNotes()
              this.addNoteDiaClose()
            } else {
              this.$message({
                message: res.data.message,
                type: 'error'
              })
            }
            this.saveShowLoading = false
          })
        }
      })
    },
    // 删除子节点
    delItem (node, data) {
      this.$confirm('确认删除?', '提示', {
        type: 'warning'
      }).then(() => {
        deleteDrStandardItem({ id: data.id }).then(res => {
          if (res.data.success) {
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.getTree()
          } else {
            this.$message({
              message: res.data.message,
              type: 'error'
            })
          }
        })
      })
    },
    // 弹窗关闭
    addDialogClose () {
      this.dialogVisibleData = false
      this.toggleDialog()
      this.getDataList()
      this.dialog.add.show = false
      this.dialog.add.editing = false
      this.checkedTop = true
      this.addForm = {
        id: undefined,
        bn: '',
        standardId: undefined,
      }
      this.form = {
        id: undefined,
        bn: '',
        content: '',
        parentId: undefined,
        typeId: undefined,
      }
    },
    ruleDialogClose () {
      this.dialog.rule.show = false
      this.form = {
        id: undefined,
        bn: '',
        content: '',
        parentId: undefined,
        typeId: undefined,
      }
    },
    noteDialogClose () {
      this.dialog.note.show = false
    },
    addNoteDiaClose () {
      this.dialog.addNote.show = false
      this.dialog.addNote.isEdit = false
      this.noteForm = {
        bn: '',
        title: '',
        picList: '',
        contentList: [{ bn: '', content: '', typeId: undefined }]
      }
      this.fileList = []
    },
    // 编辑条文
    handleEdit () {
      if (!this.unNeedData.id) {
        this.$message({
          message: '请选择一条数据',
          type: 'error'
        })
        return
      }
      this.step = 2
      this.dialog.add = {
        editing: true,
        show: true,
        loading: false,
        title: '编辑条文'
      }
      getDrStandardItemById({ id: this.unNeedData.id }).then(res => {
        if (res.data.success) {
          this.standardItemId = this.unNeedData.id
          this.addForm.id = res.data.response.id
          this.addForm.bn = res.data.response.bn
          this.addForm.standardId = res.data.response.standardId
          let tree = JSON.parse(res.data.response.content)
          this.standardTreedata = tree
        }
      })
    },
    // 删除条文
    handleDel () {
      if (!this.unNeedData.id) {
        this.$message({
          message: '请选择一条数据',
          type: 'error'
        })
        return
      }
      this.$confirm('确认删除?', '提示', {
        type: 'warning'
      }).then(() => {
        deleteDrStandardItem({ id: this.unNeedData.id }).then(res => {
          if (res.data.success) {
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.getDataList()
          } else {
            this.$message({
              message: res.data.message,
              type: 'error'
            })
          }
        })
      })
    },
    // 查看关联注
    onNotes () {
      if (!this.unNeedData.id) {
        this.$message({
          message: '请选择一条数据',
          type: 'error'
        })
        return
      }
      this.standardItemId = this.unNeedData.id
      this.getTree()
      this.getNotes()
      this.dialog.note.show = true
      this.dialog.note.title = this.unNeedData.bn
    },
    // 查看注
    checkNote (data, node) {
      if (node.level === 1) {
        this.dialog.note.title = node.data.bn
      } else {
        this.dialog.note.title = node.data.bn + node.data.content
      }

      this.standardItemId = node.data.id
      this.getNotes()
    },
    // 获取注列表
    getNotes () {
      getDrStandardItemNotesList({ standardItemId: this.standardItemId }).then(res => {
        if (res.data.success) {
          let list = []
          res.data.response && res.data.response.forEach(item => {
            let obj = {
              bn: item.bn,
              title: item.title,
              id: item.id
            }
            if (item.content !== '') {
              obj.content = JSON.parse(item.content)
            }
            if (item.picList !== '') {
              obj.picList = item.picList.split(',')
            }
            list.push(obj)
          })
          this.notesList = list
        }
      })
    },
    // 删除该注
    delNotes (item) {
      this.$confirm('确认删除该注?', '提示', {
        type: 'warning'
      }).then(() => {
        deleteDrStandardItemNotes({ id: item.id }).then(res => {
          if (res.data.success) {
            this.$message({
              message: res.data.message,
              type: 'success'
            })
            this.getNotes()
          } else {
            this.$message({
              message: res.data.message,
              type: 'error'
            })
          }
        })
      })
    },
    // 编辑该注
    editNote (item) {
      this.dialog.addNote.show = true
      this.dialog.addNote.isEdit = true
      this.noteForm = {
        id: item.id,
        bn: item.bn,
        title: item.title,
        picList: item.picList,
        contentList: item.content ? item.content : []
      }
      if (item.picList && item.picList.length > 0) {
        let fileList = []
        item.picList.map((pic, index) => {
          const file = {
            uid: index,
            status: 'success',
            response: {
              response: {
                weburl: pic
              }
            },
            url: pic,

          }
          fileList.push(file)

        })
        this.fileList = fileList
        // this.$refs.upload.uploadFiles = fileList;
      }
    },
    addNotes () {
      this.dialog.addNote.show = true
      this.dialog.addNote.isEdit = false
    },
    // 上传图片回调
    uploadSuccess (res, file) {
      if (res.success) {
        this.$message.success(res.message)
      } else {
        this.$message.error(res.message)
      }
    },
    handleChange (file, fileList) {
      this.fileList = fileList
      this.$refs.upload.clearFiles()
      this.$refs.upload.uploadFiles.push(file)
    },
    handlePictureCardPreview (file) {
      this.dialog.reviewPic.dialogImageUrl = file.url || file
      this.dialog.reviewPic.show = true
    },
    // common
    // 点击table某一行
    currentChange (val) {
      val = this.needData
    },
    // 分页
    handleCurrentChange (val) {
      this.pages.pageIndex = val
      this.getDataList()
    },
    handleSizeChange (val) {
      this.pages.pageSize = val
      this.getDataList()
    },
    // 时间格式化
    formatCreateTime: function (row, column) {
      return !row.NoticeTime || row.NoticeTime == ''
          ? ''
          : util.formatDate.format(new Date(row.NoticeTime), 'yyyy-MM-dd hh:mm')
    },
    // 表格数据分页条数累计
    indexMethod (index) {
      var currentPage = this.pages.pageIndex
      var pageSize = this.pages.pageSize
      return (index + 1) + (currentPage - 1) * pageSize
    },
  }
}
</script>

<style lang="stylus" scoped>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.dialog-content {
  display flex
  height 400px
  justify-content space-between
  overflow-y scroll


  .lf {
    width 30%
    padding-right 20px
  }

  .rt {
    flex 1
    border-left 1px solid #fafafa
    padding-left 20px
  }
}

.paragraph {
  text-indent 2em
}

.note-content {
  display flex
  flex-direction column
  align-items center

  .content {
    align-self: flex-start;
    display: flex
  }
}

.picList {
  display flex
  flex-direction column
  align-items center
  justify-content center

  img {
    object-fit contain
    cursom pointer
  }

}
</style>
